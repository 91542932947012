import React from 'react'
import bannerImage from '../assets/images/banner.jpg'
import logoImage from '../assets/images/logo.png'

export default {
  Banner: (
    <div className="banner-container">
      <div className="gradient-box"></div>
      <div className="icon-container">
        <img src={logoImage} alt="Icon" className="topicon" />
      </div>
      <img src={bannerImage} alt="Banner" className="banner-image" />
    </div>
  ),
}
